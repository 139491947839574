import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';
import cloneDeep from 'lodash.clonedeep';

import AuInput from '@au/core/lib/components/elements/AuInput';
import AuButton, { BUTTON_SIZE_SMALL } from '@au/core/lib/components/elements/AuButton';
import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import { NOOP } from '../constants';

import styles from '../css/components/annotation_tag_filter.module.scss';

export default class AnnotationFilterValue extends React.PureComponent {

  // May contain other properties defined in AuDropDown.propTypes
  static propTypes = {
    value: T.array,
    onChange: T.func
  }

  static defaultProps = {
    value: [],
    onChange: NOOP
  }

  constructor(props) {
    super(props);

    this.state = {
      values: [{
        value: '',
        idx: 0
      }]
    }

    if (this.props.value.length !== 0) {
      this.state.values = this.props.value;
    }

  }

  handleChange(idx, valueStr) {
    this.setState(prevState => {
      const values = cloneDeep(prevState.values);
      values[idx].value = valueStr.target.value;
      return { values };
    }, this.commitChanges);
  }

  handleOnClear(idx) {
    this.setState(prevState => {
      const values = cloneDeep(prevState.values);
      values[idx].value = '';
      return { values };
    }, this.commitChanges);
  }

  addValue = this.addValue.bind(this);
  addValue() {
    this.setState(prevState => {
      const values = cloneDeep(prevState.values);
      values.push({ value: '', idx: prevState.values.length });
      return { values };
    }, this.commitChanges);
  }


  removeValue(idx) {
    this.setState(prevState => {
      const values = cloneDeep(prevState.values);
      values.splice(idx, 1);
      if (values.length === 0) {
        values.push({ value: '', idx: 0 });
      }
      return { values };
    }, this.commitChanges);
  }

  commitChanges() {
    const { values } = this.state;

    let validValues = [];

    values.forEach(value => {
      if (value.value.length !== 0) {
        validValues.push(value);
      }
    });

    this.props.onChange(validValues);
  }

  renderValues() {
    if (this.state.values.length === 0) {
      this.setState({
        values: [{
          value: '',
          idx: 0
        }]
      });
    }
    return this.state.values.map(this.renderValue);
  }

  renderValue = this.renderValue.bind(this)
  renderValue(value, idx) {
    const { hintClassName } = this.props;
    let showHint = value.value.length !== 0 && value.value?.includes(',');
    let showCountryCodeError = false;
    let showMaxLengthError = false;
    if (this.props.COUNTRY_CODE && value.value.length > 2) {
      showCountryCodeError = true;
    } else if (value.value.length > 256) {
      showMaxLengthError = true;
    }
    return (
      <div key={`annotationTag_${value.idx}`}>
        <div className={cn(styles.input_container, {[styles.no_margin]: showHint})}>
          <AuInput
            value={value.value}
            createMode={true}
            onChange={this.handleChange.bind(this, idx)}
            onClear={this.handleOnClear.bind(this, idx)}
            showError={Boolean(value.value.match("\"")) || showCountryCodeError || showMaxLengthError}
            error={{
              errDisplayId: Boolean(value.value.match("\"")) && 'au.validation.invalidCharacter' || showCountryCodeError || showMaxLengthError && 'au.validation.maxLength',
              fieldDisplayId: Boolean(value.value.match("\"")) && 'au.entity.value' || showCountryCodeError && 'au.taps.filter.details.COUNTRY_CODE' || showMaxLengthError && 'au.entity.value',
              values: Boolean(value.value.match("\"")) && {validCharacters: "a-z, A-Z, 0-9, _;:.,()?&$%@+=*#[]-"} || showCountryCodeError && {length: 2} || showMaxLengthError && {length: 256}
            }}
          />
          <button
            type="button"
            className={cn(styles.delete, { [styles.disabled]: !value.value })}
            onClick={this.removeValue.bind(this, idx)}
          />
        </div>
        {
          showHint && <div className={styles.hint_container}>
            <AutoIntl displayId={''} className={styles.icon} tag="div" />
            <AutoIntl displayId={'au.annotationFilterValue.hint'} className={hintClassName} />
          </div>
        }
      </div>
    );
  }

  render() {
    return (
      <div className={styles.container}>
        {this.renderValues()}
        <AuButton
          displayId="au.addValue"
          className={styles.button}
          size={BUTTON_SIZE_SMALL}
          onClick={this.addValue}
        />
      </div>
    );
  }
}
