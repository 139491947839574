import React from "react";
import * as T from "prop-types";

import { NOOP } from "@au/core/lib/constants";
import TMC from "@autonomic/browser-sdk";
import AuDropDown from "@au/core/lib/components/elements/AuDropDown";
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import { formatMessage } from '../utils/reactIntl';

import styles from '../css/components/annotation_tag_filter.module.scss';

export class AnnotationFilterKey extends React.PureComponent {
  // May contain other properties defined in AuDropDown.propTypes
  static propTypes = {
    value: T.string,
    onChange: T.func
  };

  static defaultProps = {
    onChange: NOOP
  };

  state = { keyOptions: [] };

  keyEndpoint = new TMC.services.Feed({apiVersion: 2}).annotationFilterTags;

  componentDidMount() {
    this.keyEndpoint.list().then(resp => {
      this.setState({
        keyOptions: resp.data.map((item) => ({
          displayString: formatMessage({id: `au.taps.filter.details.${item.key}`}),
          val: item.key
        }))
      });
    }, createResponseAlertMessage);
  }

  render() {
    const { createMode, tagType, value, onChange, ...otherProps } = this.props;
    const { keyOptions } = this.state;
    const hasOptions = keyOptions.length === 0;

    return (
      <AuDropDown
        {...otherProps}
        className={styles.key_input}
        toggleClassName={`toggle_${tagType}`}
        key={`attributeTag_${tagType}`}
        disabled={hasOptions}
        selection={value ? formatMessage({id: `au.taps.filter.details.${value}`}) : ''}
        selectionClassName={styles.selection}
        options={keyOptions}
        selectOption={onChange}
        createMode={!createMode}
        allowTyping={true}
      />
    );
  }
}